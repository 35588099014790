<template>
     <section class="position">
    <div class="position-wrapper">
      <div class="position-inner">
        <header class="header">
          <router-link to="/" class="header-logo">
            <img src="../assets/images/logo-header.svg" alt="logo" />
          </router-link>
        </header>

        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link class="breadcrumb-link" to="/">Головна</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link class="breadcrumb-link" to="/position"
              >Позиція партії</router-link
            >
          </li>
          <li class="breadcrumb-item"> Щодо оцінки дій влади з боку бізнесу.</li>
        </ul>
        <div>
          <h3 class="position-title">Позиція Партії</h3>
        </div>
        <div class="position-img">
          <img src="../assets/images/position3.svg" alt="tarrifs" />
        </div>
        <h5 class="position-description">
          Щодо оцінки дій влади з боку бізнесу
        </h5>
        <router-link to="/position" class="btn-back">
          <span class="btn-back_icon">
            <img src="../assets/images/btn-back.svg" alt="back" />
          </span>
          Повернутись
        </router-link>
      </div>
      <div class="page">
          <div class="position-text">
              <h4> Портал «Діло» опитав власників і керівників бізнесу, які головні
              помилки допустила українська влада в 2020 році. Їх основні тези:</h4>
              <ul>
                <li>
                Головними «факапамі» бізнесмени назвали відсутність стратегії
                боротьби з пандемією СOVID-19 і комплексних заходів підтримки
                підприємництва під час карантину, низька якість комунікації влади з
                суспільством, а також неприйнятну кадрову політику, популізм і
                заморозку антикорупційної реформи. Детальніше:
                </li>
                <li>немає чіткої стратегії боротьби з коронавірусом;</li>
            <li>
              безсистемне і ситуативне рішення по локдаунам, що не були
              прораховані і проаналізовані в достатній мірі, для чого не були
              притягнуті сильні українські та міжнародні експерти, а також
              бізнес;
            </li>
            <li>
              недостатньо чіткими були позиція і план дій влади в частині
              боротьби з коронавірусом і в частині інформування населення;
            </li>
            <li>
              головна помилка - відсутність чіткої стратегії, якісних програм
              комунікації та підтримки сфер бізнесу, які постраждали найбільше;
            </li>
            <li>
              сотні тисяч українських бізнесменів зіткнулися з безпрецедентною
              ситуацією, коли довелося призупиняти або повністю закривати бізнес
              в лічені дні. Мільйони українців, зайняті в приватному бізнесі, в
              тій чи іншій мірі постраждали від коронакризи. При цьому держава
              не розробила жодних комплексних заходів для підтримки
              підприємництва, а точкові заходи (на кшталт 8 тисяч гривень для
              ФОПів), скоріше, створять навантаження на бюджет країни, ніж
              реально допоможуть чинним бізнесам в Україні;
            </li>
            <li>
              абсолютно незрозумілий карантин вихідного дня, абсолютно нелогічні
              і незрозумілі заходи, які істотно негативно вплинули на бізнес;
              перший локдаун був передчасним і влада не змогла ним скористатися
              ефективно: не підготували лікарні, додаткові місця, обладнання,
              кадри, заходи захисту; для бізнесу нічого не було зроблено, від
              чого сильно постраждала економіка країни;
            </li>
            <li>
              ми потрапили в період невизначеності, і в цей період завдання
              управлінців, для влади - дати хоч якийсь дороговказ, якусь
              визначеність;
            </li>
            <li>
              основною помилкою влади є перший локдаун: його ввели в два тижні і
              дали зовсім інші індикативи і очікування бізнесу, а затягли на три
              місяці. Він суттєво вдарив по економіці. Тоді такого жорсткого і
              тривалого локдауна вводити не потрібно було;
            </li>
            <li>
              не змінилася парадигма відносин влади і бізнесу. Країна як була,
              так залишилася комфортної для силовиків, чиновників, всіляких
              "вахтерів" і «рєшал». А платники податків ними сприймаються не як
              основа держави, а як вічна дійна корова, яку краще менше годувати,
              але частіше доїти ...;
            </li>
            <li>
              не дивлячись на те, що масштабну програму будівництва доріг і
              реконструкції дорожньої інфраструктури по всій країні можна
              вважати перемогою, так як інфраструктурна реновація впливає не
              тільки на окремі галузі, а й дає відчутний поштовх для економіки в
              цілому, на жаль, уряд повністю програло боротьбу з корупцією в
              містобудівній сфері; на даному етапі можна говорити про повне
              фіаско реформи ДАБІ, що посилило і так непросте становище
              будівельної галузі;
            </li>
            <li>гальмування реформ;</li>
            <li>
              головна помилка і проблема влади - це кадри. Президенту не
              вистачає в команді справжніх досвідчених
              професіоналів-технократів, які могли б принести реальну користь
              розвитку країни.
            </li>
            <li>
              в української влади є національна традиція - "ходити по граблях",
              постійно допускати одні й ті ж помилки: популізм і недостатній
              рівень професійного підходу, зокрема, в питаннях підбору кадрів.
            </li>
            <li>
              в кадровій політиці Офісу президента України слід було б
              керуватися критерієм професіоналізму, а не лояльності.
            </li>
            <li>
              Головний факап влади – комунікаційний. У нас зараз низька якість
              комунікації. Хто говорить, що саме говорить, як коментують
              провали, коли вони є, як реагують на потенційні виклики? Страшно
              не те, що в країні "жопа", а то, що країна не знає, що
              відбувається. Розповідайте, пояснюйте, навіщо ви щось робите.
            </li>
            <li>
              Остаточний і демонстративний ігнор своїх передвиборних обіцянок,
              які, власне, і привели до влади. Влада не скористалися виданими
              кредитом довіри.
            </li>
              </ul>
            </div>
      
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {
  }
}
</script>

<style scoped lang="less">
.position {
  background: #f2f2f2;
  height: 100vh;
  &-wrapper {
    display: flex;
    overflow-y: auto;
  }
  &-inner {
    width: 30%;
    height: 100vh;
    background: #fff;
    padding: 80px;
    overflow-y: auto;
  }
  &-title {
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -0.392px;
    color: #1a2744;
    margin-bottom: 144px;
  }
  &-img {
    margin-bottom: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-description {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.224px;
    color: #ffc900;
    margin-bottom: 96px;
  }
  .page {
    width: 70%;
    height: 100vh;
    padding: 80px 80px 0 80px;
    overflow-y: auto;
  }
    &-text {
         max-width: 1120px;
         margin: 0 auto;
      }
}
.hooper:focus {
  outline: none !important;
}
.btn-back {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #1a2744;
  text-decoration: none;
  &_icon {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #ffc900;
    box-shadow: 0px 24px 24px -14px rgba(249, 198, 35, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
}

.position- .header {
  width: 100%;
  padding-top: 80px;
}
.header-logo {
  width: 253px;
  height: 51px;
  margin-bottom: 22px;
  display: inline-block;
}

/* breadcrumb */
.breadcrumb {
  list-style: none;
  margin-bottom: 45px;
}
.breadcrumb-item {
  display: inline;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.112px;
  color: #1a2744;
  margin-right: 20px;
}

.breadcrumb-link {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.112px;
  color: #ffc900;
  text-decoration: none;
   position: relative;
   &::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 15px;
        background: #1a2744;
        bottom: 1px;
        right: -11px;
  }
}

.page-title {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.42px;
  color: #1a2744;
}
.position-text {
  overflow-y: auto;
}
.position-text h4 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.224px;
  color: #1a2744;
  margin-bottom: 40px;
}
.position-text ul {
   padding-left: 25px;
   text-align: justify;
}
.position-text li {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.14px;
  color: #1a2744;
  margin-bottom: 20px;
}

@media screen and (max-width: 1920px) {
  .position {
      &-inner {
        max-width: 640px;
        width: 30%;
      }
      .page {
        width: 70%;
      }
      &-text {
         max-width: 1120px;
         margin: 0 auto;
      }
  }
}
@media screen and (max-width: 1440px) {
  .position-title {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  .position-img {
    margin-bottom: 30px;
  }
  .position-description {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 35px;
  }
  .page {
    padding: 40px;
  }
  .position-text h4 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .position-inner {
    padding-top: 40px;
  }
  .position .page {
    padding: 40px 50px 50px 50px;
  }
}

.breadcrumb {
  margin-bottom: 25px;
}
</style>